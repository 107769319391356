import styled from "styled-components/native";
import { theme } from "../../global/theme";

export const Container = styled.TouchableOpacity`
  width: 180px;
	border-radius: 10px;
	border: 1px solid ${theme().colors.border};
`;

export const ImageContainer = styled.View`
	height: 180px;
	width: 100%;
	position: relative;
	border-radius: 10px;
`;

export const Image = styled.Image`
	flex: 1;
	border-radius: 10px;
`;

export const DiscountIcon = styled.Image`
	position: absolute;
	top: 8px;
	left: 8px;
	width: 20px;
	height: 20px;
	fill: ${theme().colors.primary};
`;

export const QuantityContainer = styled.View.attrs({
	onStartSetSholderResponder: () => true,
})`
	position: absolute;
	bottom: 4px;
	right: 0;
	width: 100%;
`;

export const DescriptionContainer = styled.View`
	height: 92px;
	border-top-width: 1px;
	padding: 8px;
	position: relative;
	border-top-color: ${theme().colors.border};
`;

export const Title = styled.Text`
	font-size: 12px;
	font-family: ${theme().fonts.regular};
`;

interface PriceTextProps {
	isDiscount: boolean;
}

export const PriceText = styled.Text<PriceTextProps>`
	position: absolute;
	bottom: 2px;
	right: 5px;
	font-size: 20px;
	font-family: ${theme().fonts.bold};
	color: ${({ isDiscount }) =>
	isDiscount ? theme().colors.black : theme().colors.black};
	justify-content: center;
	align-items: center;
`;

export const RiskedPrice = styled.Text`
	font-size: 14px;
	position: absolute;
	left: 8px;
	bottom: 9px;
	text-decoration: line-through;
	font-family: ${theme().fonts.regular};
	color: ${theme().colors.gray};
`;

export const ContainerMessage = styled.View<{ color: string, unavailable?: boolean }>`
	position: absolute;
	padding: 5px;
	text-align: center;
	background-color: ${({ color }) => theme(color).colors.primary};
	top: 10px;
	width: 50px;
	border-end-end-radius: 10px;
	border-start-end-radius: 10px;

	${(props) =>
		props.unavailable &&
		`
width: 100%;
top: 2px;
padding: 15px;
border-end-end-radius: 0px;
border-start-end-radius: 0px;
    `}
`;

export const Message = styled.Text`
	color: ${theme().colors.white};
	font-size: 11px;
	font-family: ${theme().fonts.bold};
`;
export const ImageContent = styled.View<{ color: string, logo: boolean }>`
	justify-content: center;
	align-items: center;
	flex: 1;
	background-color: ${({ color, logo }) => theme(!logo ? color : "#fff").colors.primary};
	opacity: ${({ logo }) => logo ? 1 : 0.5}
`;