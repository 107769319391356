import React from "react";
import { useCart } from "../../hooks/use-cart";
import { CartItem as CartItemModel } from "../../models/cart";
import { NumberToCurrency } from "../../utils/numberToCurrency";
import { QuantityPicker } from "../QuantityPicker";
import {
	CartDetailsContainer,
	CartImageContainer,
	CartItemContainer,
	CartItemImage,
	CartItemName,
	CartItemPrice,
	CartPriceContainer,
	CartItemRow,
	RiskedPrice,
} from "./styles";
import { ProductItem } from "../../store/category-product";
import { IMAGE_URL_DEFAULT } from "../../utils/contants";

interface CartItemProps {
	item: CartItemModel;
	isLast: boolean;
	action?: () => void;
	setProductSelected: (value: ProductItem | null) => void
}

export const CartItem: React.FC<CartItemProps> = ({
	item,
	action,
	isLast,
	setProductSelected
}) => {
	const myCart = useCart();

	const onChangeQuantity = (item: CartItemModel, qnty: number) => {
		myCart.updateCartItem(item as unknown as ProductItem, qnty, false, false, false);
	};

	return (
		<CartItemContainer isLast={isLast}>
			<CartImageContainer
				onPress={() => setProductSelected(item)}
				testID={`cart_item_${item._id}`}
			>
				<CartItemImage
					resizeMode="contain"
					source={{ uri: item?.image || IMAGE_URL_DEFAULT }}
					testID={`cart_item_${item._id}_image`}
				/>
			</CartImageContainer>
			<CartDetailsContainer>
				{true ? (
					<>
						<CartItemRow>
							<CartItemName
								numberOfLines={2}
								bold
								testID={`cart_item_${item._id}_name`}
							>
								{item?.productName?.toUpperCase()}
							</CartItemName>
							<CartItemPrice
								numberOfLines={2}
								testID={`cart_item_${item._id}_qtd`}
								style={{ textTransform: "lowercase" }}
							>
								{item.quantity > 1
									? `${item.quantity}${item.unidadeSigla}`
									: `${item.quantity}${item.unidadeSigla}`}
							</CartItemPrice>
						</CartItemRow>
					</>
				) : (
					<>
						<CartItemName
							numberOfLines={2}
							testID={`cart_item_${item._id}_name`}
						>
							{item?.productName?.toUpperCase()}
						</CartItemName>
						<QuantityPicker
							style={{ marginRight: "auto" }}
							value={item.quantity}
							maxValue={item.quantity + 2}
							onChange={(value) =>
								onChangeQuantity(item, value - item.quantity)
							}
							testID={`${item._id}`}
						/>
					</>
				)}
			</CartDetailsContainer>
			<CartPriceContainer>
				{item?.price_final_in_cents <
					item.price && (
						<RiskedPrice testID="old_price">
							{NumberToCurrency(item.price)}
						</RiskedPrice>
					)}
				<CartItemPrice testID={`cart_item_${item._id}_price`}>
					{NumberToCurrency(
						((item?.price_final_in_cents || item.price) * item.quantity)
					)}
				</CartItemPrice>
			</CartPriceContainer>
		</CartItemContainer>
	);
};
